import styled, { css } from 'styled-components';

import { Translated } from '@/services/locale';
import { useTheme, Theme } from '@/services/theme';
import { addRem } from '@/shared/styles';
import { ConvertPropsToStyled, convertPropsToStyled } from '@/shared/utils/convertToStyledProps';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body1: 'p',
  body2: 'p',
  label: 'span',
  nav: 'span',
  span: 'span',
};

export type TypographyProps = Omit<React.HTMLProps<HTMLElement>, 'size'> & {
  align?: 'left' | 'right' | 'center';
  className?: string;
  color?: 'primary' | 'secondary' | 'tertiary' | 'beta' | 'error' | 'success';
  component?: React.ElementType;
  display?: 'block' | 'inline-block' | 'inline';
  float?: 'left' | 'right';
  fontFamily?: 'body' | 'heading' | 'gramatika';
  heading?: boolean;
  letterSpacing?: number | string;
  lineHeight?: number | string;
  margin?: string;
  mx?: string | number;
  my?: string | number;
  mt?: string | number;
  mr?: string | number;
  mb?: string | number;
  ml?: string | number;
  shadow?: boolean;
  size?: number | string;
  smallCaps?: boolean;
  transform?: 'capitalize' | 'lowercase' | 'none' | 'uppercase';
  variant?: keyof typeof variantMapping;
  weight?: 400 | 500 | 700;
};

type StyledProps = ConvertPropsToStyled<TypographyProps> & { theme: Theme };

const StyledTypographyRoot = styled.span`
  ${({
    $align,
    $color,
    $display,
    $float,
    $fontFamily,
    $heading,
    $letterSpacing,
    $lineHeight,
    $margin,
    $mt,
    $mr,
    $mb,
    $ml,
    $mx,
    $my,
    $shadow,
    $size,
    $smallCaps,
    theme,
    $transform,
    $variant = 'span',
    $weight,
  }: StyledProps): CSSRuleList => {
    const colors = {
      primary: theme.colors.text.primary,
      secondary: theme.colors.text.secondary,
      tertiary: theme.colors.text.tertiary,
      beta: theme.colors.gray12,
      error: theme.colors.error,
      success: theme.colors.success,
    };

    const styleOverrides = css`
      ${$align !== undefined && `text-align: ${$align};`}
      ${$color !== undefined && `color: ${colors[$color]};`}
      ${$display !== undefined && `display: ${$display};`}
      ${$float !== undefined && `float: ${$float};`}
      ${$fontFamily !== undefined &&
      `font-family: ${
        // eslint-disable-next-line no-nested-ternary
        $fontFamily === 'body'
          ? theme.fonts.main
          : $fontFamily === 'heading'
          ? theme.fonts.mono
          : 'Gramatika'
      } ;`}
      ${$heading && `font-family: ${theme.fonts.heading};`}
      ${$letterSpacing !== undefined && `letter-spacing: ${addRem($letterSpacing)};`}
      ${$lineHeight !== undefined && `line-height: ${$lineHeight};`}
      ${$margin !== undefined && `margin: ${$margin};`}
      ${$mx && `margin-left: ${addRem($mx)}; margin-right: ${addRem($mx)};`}
      ${$my && `margin-top: ${addRem($my)}; margin-bottom: ${addRem($my)};`}
      ${$mt && `margin-top: ${addRem($mt)};`}
      ${$mr && `margin-right: ${addRem($mr)};`}
      ${$mb && `margin-bottom: ${addRem($mb)};`}
      ${$ml && `margin-left: ${addRem($ml)};`}
      ${$shadow && `text-$shadow: 0 0 4px ${theme.colors.text.primary}80;`}
      ${$size !== undefined && `font-size: ${$size}rem;`}
      ${$smallCaps && `font-variant: small-caps; text-transform: lowercase;`}
      ${$transform && `text-transform: ${$transform};`}
      ${$weight !== undefined && `font-weight: ${$weight};`}
    `;

    const headingStyles = css`
      color: ${theme.colors.text.primary};
      font-family: ${theme.fonts.mono};
      font-weight: 700;
    `;

    const variantStyles = {
      h1: css`
        ${headingStyles}
        font-size: 2.8rem;
        line-height: 1.6;
      `,
      h2: css`
        ${headingStyles}
        font-size: 1.8rem;
        line-height: 1.3;
      `,
      h3: css`
        ${headingStyles}
        font-size: 1.4rem;
        line-height: 1.5;
      `,
      h4: css`
        ${headingStyles}
        font-size: 1.2rem;
        line-height: 1.5;
      `,
      body1: css`
        color: ${theme.colors.text.primary};
        font-family: ${theme.fonts.main};
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
      `,
      body2: css`
        color: ${theme.colors.text.secondary};
        font-family: ${theme.fonts.main};
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.4;
      `,
      label: css`
        display: block;
        color: ${theme.colors.text.secondary};
        font-family: ${theme.fonts.main};
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.2;
      `,
      nav: css`
        color: ${theme.colors.text.secondary};
        font-family: ${theme.fonts.heading};
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.3;
      `,
      span: css`
        color: ${theme.colors.text.secondary};
        font-family: ${theme.fonts.main};
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.4;
      `,
    };

    return css`
      ${variantStyles[$variant]};
      ${styleOverrides};
    `;
  }}
`;

const Typography: React.FC<TypographyProps> = ({
  children,
  component,
  variant = 'span',
  className,
  ...props
}) => {
  const { theme } = useTheme();

  const Component = component || variantMapping[variant];

  return (
    <StyledTypographyRoot
      as={Component}
      theme={theme}
      $variant={variant}
      className={className}
      {...convertPropsToStyled(props)}
    >
      <Translated>{children}</Translated>
    </StyledTypographyRoot>
  );
};

export { Typography };
