import { PointsEntity } from '@/features/restakeLtrs/types';
import { ReactComponent as MlwPoints } from '@/shared/assets/mlwPoints.svg';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { ReactComponent as AmphorPoints } from '@/shared/assets/amphorPoints.svg';
import { ReactComponent as RenzoPoints } from '@/shared/assets/renzoPoints.svg';
import { ReactComponent as SSVPoints } from '@/shared/assets/ssv-network.svg';
import { ReactComponent as MindNetwork } from '@/shared/assets/mind-network.svg';
import QuasarPoints from '@/shared/assets/quasarPoints.png';
import ObolPoints from '@/shared/assets/obol-light.png';

export interface PointsIconProps {
  entity: PointsEntity;
}

export const PointsIcon = ({ entity }: PointsIconProps) => {
  if (entity === 'mellow') {
    return <MlwPoints />;
  }

  if (entity === 'symbiotic') {
    return <SpriteIcon icon="symbiotic" size={2} />;
  }

  if (entity === 'amphor') {
    return <AmphorPoints height={20} width={20} />;
  }

  if (entity === 'renzo') {
    return <RenzoPoints height={20} width={20} />;
  }

  if (entity === 'obol') {
    return <img alt="Obol" src={ObolPoints} height={20} width={20} />;
  }

  if (entity === 'ssv') {
    return <SSVPoints height={20} width={20} />;
  }

  if (entity === 'quasar') {
    return <img alt="Quasar" src={QuasarPoints} height={20} width={20} />;
  }

  if (entity === 'vfhe') {
    return <MindNetwork height={20} width={20} />;
  }

  return null;
};
