import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

enum SIZE_VARIANT {
  STANDARD = 'standard',
  SMALLER = 'smaller',
}

type Type = 'button' | 'link';

type Props = {
  type?: Type;
  hidden?: boolean;
  variant?: SIZE_VARIANT;
  href?: string;
  children: React.ReactNode;
  disabled?: boolean;
};

const getButtonType = (type: Type) => {
  switch (type) {
    case 'link':
      return 'a';
    default:
      return 'button';
  }
};

const getStyledButton = (type: Type) => styled[getButtonType(type)]`
  text-transform: uppercase;
  position: relative;
  padding: ${({ variant, disabled }: { variant: SIZE_VARIANT; disabled: boolean }) =>
    variant === SIZE_VARIANT.SMALLER || disabled ? '6px 6px 6px 28px' : '14px 28px'};
  font-family: ${({ theme }: { theme: Theme }) => theme.fonts.noto};
  font-size: ${({ variant }: { variant: SIZE_VARIANT }) =>
    variant === SIZE_VARIANT.SMALLER ? '1.6rem' : '1.4rem'};
  line-height: 1.8rem;
  font-weight: 700;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  background: ${({ theme, disabled }: { theme: Theme; disabled: boolean }) =>
    disabled ? theme.colors.gray16 : theme.colors.restakePink};
  border: none;
  border-radius: 30px;
  cursor: pointer;
  opacity: ${(props: { hidden: boolean }) => (props.hidden ? 0 : 1)};
  visibility: ${(props: { hidden: boolean }) => (props.hidden ? 'hidden' : 'visible')};
  border-top: 1.5px solid
    ${({ theme, disabled }: { theme: Theme; disabled: boolean }) =>
      disabled ? theme.colors.border.translucentDisabled : theme.colors.border.translucentDark1};
  border-bottom: 1.5px solid
    ${({ theme, disabled }: { theme: Theme; disabled: boolean }) =>
      disabled ? theme.colors.border.translucentDisabled : theme.colors.border.translucentDark1};
  border-right: 1.5px solid
    ${({ theme, disabled }: { theme: Theme; disabled: boolean }) =>
      disabled ? theme.colors.border.translucentDisabled : theme.colors.border.translucentDark1};
  border-left: 1.5px solid
    ${({ theme, disabled }: { theme: Theme; disabled: boolean }) =>
      disabled ? theme.colors.border.translucentDisabled : theme.colors.border.translucentDark1};
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: ${({ disabled }: { disabled: boolean }) => (disabled ? 'auto' : 'pointer')};
  text-decoration: none;
  width: max-content;
  transition: background 300ms;

  &:hover {
    background: ${({ theme, disabled }: { theme: Theme; disabled: boolean }) =>
      disabled ? theme.colors.gray16 : '#FF64A5'};
  }

  @media (max-width: ${breakpoints.mobileMD}px) {
    font-size: 1.3rem;
    padding: 10px 15px;
  }
`;

const Button: React.FC<Props> = ({
  children,
  type = 'button',
  variant = SIZE_VARIANT.STANDARD,
  hidden = false,
  href,
  disabled,
}) => {
  const { theme } = useTheme();
  const Button = getStyledButton(type);

  return (
    <Button
      type={type}
      disabled={disabled}
      hidden={hidden}
      variant={variant}
      theme={theme}
      href={href}
    >
      {children}
    </Button>
  );
};

Button.defaultProps = {
  type: 'button',
  hidden: false,
};

export { Button, SIZE_VARIANT as SizeVariant };
