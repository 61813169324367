import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';
import { useTheme } from '@/services/theme';

import { ReactComponent as Steakhouse } from './images/steakhouse.svg';
import { ReactComponent as P2p } from './images/p2p.svg';
import { ReactComponent as Renzo } from './images/renzo.svg';
import { ReactComponent as Re7 } from './images/re7.svg';
import { ReactComponent as K3 } from './images/k3.svg';
import { ReactComponent as Infstones } from './images/infstones.svg';
import { ReactComponent as ChorusOne } from './images/chorusone.svg';
import { ReactComponent as Luganodes } from './images/luganodes.svg';
import { ReactComponent as Rockx } from './images/rockx.svg';
import { ReactComponent as Quasar } from './images/quasar.svg';
import { ReactComponent as MEVCapital } from './images/mevcapital.svg';
import { PageSection } from '../../components/PageSection';

const IconWrapper = styled.div`
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.mobileLG}px) {
    width: 186px;
    height: 80px;
  }

  .StyledLink__soon-badge {
    position: absolute;
    top: -16px;
    right: -16px;
    border-radius: 50px;
    background: #ec0b38;
    font-weight: 700;
    border: 5px solid #101115;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px 10px 7px;
  }
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  font-family: Gramatika;

  border-radius: 16px;
  gap: 16px;
  @media (min-width: ${breakpoints.tabletMD}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .Curators__image {
    border: 1.4px solid rgb(31, 32, 40);
    border-radius: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    height: 88px;
    @media (min-width: ${breakpoints.tabletMD}px) {
      gap: 16px;
      height: 128px;
    }
  }
`;

const curators = [
  () => (
    <IconWrapper>
      <Steakhouse />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <P2p />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Renzo />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Re7 />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <K3 />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Infstones />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <ChorusOne />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Luganodes />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Rockx />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <Quasar />
    </IconWrapper>
  ),
  () => (
    <IconWrapper>
      <MEVCapital />
    </IconWrapper>
  ),
];

const Curators = () => {
  const { theme } = useTheme();

  return (
    <PageSection disableIndent title="Curators">
      <StyledContent theme={theme}>
        {curators.map((Image, index) => (
          <div key={index} className="Curators__image">
            <Image key={index} />
          </div>
        ))}
      </StyledContent>
    </PageSection>
  );
};

export { Curators };
