/* eslint-disable no-restricted-syntax */
import { useQuery } from '@tanstack/react-query';
import ky from 'ky';

import { VaultResponse } from '@/features/restakeLtrs/types';

export function useVaultsData() {
  return useQuery({
    queryKey: ['restake', 'api', 'v1/vaults'],
    queryFn: () =>
      ky(`https://points.mellow.finance/v1/vaults`)
        .json()
        .catch(() => []) as Promise<VaultResponse[]>,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
