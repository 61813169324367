import styled from 'styled-components';

import { useTheme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

import auditorIcon from './auditorIcon.svg';

const FeaturesStyled = styled.section`
  display: flex;
  border: 1px solid #1f2028;
  background: #18191f;
  border-radius: 16px;
  line-height: 1.4;
  font-size: 16px;
  font-family: Gramatika;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;

  padding: 16px;
  flex-direction: column;
  gap: 8px;
  @media (min-width: ${breakpoints.tabletLG}px) {
    gap: 16px;
    padding: 25px;
    flex-direction: row;
  }
`;

const Features = () => {
  const { theme } = useTheme();
  return (
    <FeaturesStyled theme={theme}>
      <span>Use LRTs issued by industry leaders:</span> <img src={auditorIcon} alt="symbiotic" />
    </FeaturesStyled>
  );
};

export { Features };
