/* eslint-disable no-restricted-syntax */
import styled from 'styled-components';
import { useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import ContentLoader from 'react-content-loader';

import { useDimensions } from '@/shared/utils/useDimensions';
import { useTheme, Theme } from '@/services/theme';
import { landingHeaderLinks } from '@/shared/mocks/navigationLinks';
import { breakpoints } from '@/shared/styles';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Logo } from '@/shared/components/Logo';
import { Typography } from '@/shared/components/Typography';
import { formatNumber } from '@/shared/utils';
import { PointsIcon } from '@/shared/components/PointsIcon';

import { MobileMenu } from './components/MobileMenu';
import { LandingContainer } from '../LandingContainer';
import { useVaultsData } from '../../useLandingData';

const StyledHeader = styled.header`
  position: ${({
    isSticky,
    isNotVisibleInRange,
  }: {
    isSticky: boolean;
    isNotVisibleInRange: boolean;
  }) => (isSticky && !isNotVisibleInRange ? 'sticky' : 'relative')};
  top: 0;
  z-index: 1000;
  box-shadow: ${(props: { isSticky: boolean; theme: Theme }) =>
    props.isSticky ? `0px 2px 2px 0px #00000080, 0px -1px 0px 0px #FFFFFF0D inset` : 'inherit'};

  background-color: ${(props: { isSticky: boolean; theme: Theme }) =>
    props.isSticky ? props.theme.colors.background.blur : 'transparent'};
  transition: ${({
    isHeaderShow,
    isSticky,
    isNotVisibleInRange,
  }: {
    isHeaderShow: boolean;
    isSticky: boolean;
    isNotVisibleInRange: boolean;
  }) => {
    if (isNotVisibleInRange) {
      return 'none';
    }

    if (!isSticky && !isHeaderShow) {
      return 'none';
    }

    if (isSticky && !isHeaderShow) {
      return 'transform 0.4s ease';
    }

    if (isSticky && isHeaderShow) {
      return 'transform 0.4s ease';
    }

    return 'none';
  }};
  transform: translateY(
    ${({ isSticky, isHeaderShow }: { isSticky: boolean; isHeaderShow: boolean }) => {
      if (isSticky && !isHeaderShow) {
        return '-10rem';
      }

      if (isHeaderShow) {
        return '0';
      }

      if (isSticky && isHeaderShow) {
        return '0';
      }

      return '0';
    }}
  );

  backdrop-filter: ${(props: { isSticky: boolean }) => (props.isSticky ? 'blur(20px)' : 'none')};
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 91px;

  > a {
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.tabletLG}px) {
    padding: ${(props: { isSticky: boolean }) => (props.isSticky ? '20px' : '29px')} 0;
    min-height: 48px;
  }
`;

const TvlWrapper = styled.div`
  height: 38px;
  width: 480px;
  display: flex;
  flex-direction: row;
  background: #2a2c33;
  border-radius: 50px;

  .section_tvl {
    border-radius: 50px 0 0 50px;
    padding: 9px 20px;
    background: #41444e;
  }

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .section_rewards {
    padding: 9px 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    border-radius: 0 50px 50px 0;
  }
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background: #40424d;
`;

const SocialWrap = styled.div`
  display: ${({ hidden }: { hidden: boolean }) => (hidden ? 'none' : 'flex')};
  align-items: center;
`;

const StyledIconLink = styled.a`
  margin-right: 12px;
  transition:
    opacity 0.3s ease,
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #2a2c33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  &:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StyledLogo = styled.div`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 2.3rem;

  @media (max-width: ${breakpoints.tabletLG}px) {
    max-width: 95px;
  }
  @media (max-width: ${breakpoints.mobileLG}px) {
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.mobileMD}px) {
    display: ${({ isHidden }: { isHidden: boolean }) => (isHidden ? 'none' : 'inline-flex')};
    gap: 1.3rem;
  }
`;

const TVL = () => {
  const { theme } = useTheme();
  const { innerWidth } = useDimensions();
  const isTabletSize = innerWidth <= breakpoints.tabletLG;
  const { isLoading, data: vaultsData } = useVaultsData();

  const tvl = (vaultsData || []).reduce((acc, vault) => acc + parseFloat(vault.tvl_usd), 0);
  const apr = Math.max(...(vaultsData || []).map(vault => vault.apr || 0));

  let totalSymbioticPoints = 0;
  let totalMellowPoints = 0;
  if (vaultsData) {
    for (const vault of vaultsData) {
      for (const point of vault.points) {
        if (point.id === 'symbiotic') {
          totalSymbioticPoints += parseFloat(point.value);
        }
        if (point.id === 'mellow') {
          totalMellowPoints += parseFloat(point.value);
        }
      }
    }
  }

  if (isTabletSize) {
    return null;
  }

  if (isLoading || !vaultsData) {
    return (
      <ContentLoader
        speed={2}
        width="480px"
        height="38px"
        backgroundColor={theme.colors.card}
        foregroundColor={theme.colors.newGray2}
        style={{ borderRadius: '50px' }}
      >
        <rect x="0" y="0" width="100%" height="100%" />
      </ContentLoader>
    );
  }

  return (
    <TvlWrapper theme={theme}>
      <div className="section section_tvl">
        <Typography variant="body1" size={1.4} fontFamily="gramatika" weight={700}>
          TVL:
        </Typography>
        <Typography variant="body1" size={1.4} fontFamily="gramatika" weight={400}>
          ${formatNumber(tvl)}
        </Typography>
      </div>

      <div className="section_rewards">
        <div className="section">
          <Typography variant="body1" size={1.4} fontFamily="gramatika" weight={700}>
            REWARDS:
          </Typography>
          <Typography variant="body1" size={1.4} fontFamily="gramatika" weight={400}>
            {apr ? apr.toFixed(1) : ''}% APR
          </Typography>
        </div>
        <Divider />
        <div className="section">
          <PointsIcon entity="mellow" />
          <Typography variant="body1" size={1.4} fontFamily="gramatika" weight={400}>
            {formatNumber(totalMellowPoints || 0, 2)}
          </Typography>
        </div>
        <Divider />
        <div className="section">
          <PointsIcon entity="symbiotic" />
          <Typography variant="body1" size={1.4} fontFamily="gramatika" weight={400}>
            {formatNumber(totalSymbioticPoints || 0, 2)}
          </Typography>
        </div>
      </div>
    </TvlWrapper>
  );
};

const Header = ({ stickyComponent, isMobile }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isHeaderShow, setIsHeaderShow] = useState(false);
  const [isNotVisibleInRange, setIsNotVisibleInRange] = useState(false);
  const visibleRange = { min: 0, max: 100 };
  const headerRef = useRef<HTMLElement>(null);
  const { innerWidth } = useDimensions();
  const { theme } = useTheme();
  const isTabletSize = innerWidth <= breakpoints.tabletLG;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleScroll = () => {
    if (stickyComponent.current) {
      const scrollThroughHeader = headerRef.current!.scrollHeight;
      const topScroll = window.pageYOffset || document.documentElement.scrollTop;
      const isNotVisibleInMinRange = topScroll - scrollThroughHeader >= visibleRange.min;
      const isNotVisibleInMaxRange = topScroll - scrollThroughHeader <= visibleRange.max;
      const viewportHeight = window.innerHeight;
      const isCrossedStickyComponent = stickyComponent.current.getBoundingClientRect().top <= 0;
      const isCrossedViewportHeight = topScroll >= viewportHeight;
      setIsHeaderShow(isCrossedViewportHeight || isCrossedStickyComponent);
      setIsSticky(topScroll >= scrollThroughHeader);
      setIsNotVisibleInRange(isNotVisibleInMinRange && isNotVisibleInMaxRange);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const handleLogoClick = () => {
    if (isMenuOpen) {
      toggleMenu();
    }
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // });

  return (
    <StyledHeader
      ref={headerRef}
      theme={theme}
      isSticky={isSticky}
      isHeaderShow={isHeaderShow}
      isNotVisibleInRange={isNotVisibleInRange}
    >
      <LandingContainer maxWidth={1200}>
        <Wrap isSticky={isTabletSize || isSticky}>
          <HashLink onClick={handleLogoClick} to="#top" smooth>
            <StyledLogo isSticky={isSticky} isHidden={isMobile && isMenuOpen}>
              <Logo />
            </StyledLogo>
          </HashLink>

          <TVL />

          <SocialWrap isSticky={isSticky}>
            {landingHeaderLinks.social.map(item => {
              const rel = item.target === '_blank' ? 'noopener noreferrer' : '';

              return (
                <StyledIconLink
                  key={item.name}
                  href={item.to}
                  target={item.target}
                  rel={rel}
                  title={item.title}
                >
                  <SpriteIcon icon={item.name} color="#fff" size={item.size} />
                </StyledIconLink>
              );
            })}
          </SocialWrap>
        </Wrap>
      </LandingContainer>
      <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} hidden={!isTabletSize} />
    </StyledHeader>
  );
};

export { Header };
