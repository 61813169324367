import { Network } from '@/shared/types';
import ethereumBareStrategies from '@/configs/ethereum-bare-strategies.json';
import ethereumArchiveStrategies from '@/configs/ethereum-archive-strategies.json';
import polygonBareStrategies from '@/configs/polygon-bare-strategies.json';
import arbitrumArchiveStrategies from '@/configs/arbitrum-archive-strategies.json';
import moonbeamArchiveStrategies from '@/configs/moonbeam-archive-strategies.json';
import polygonArchiveStrategies from '@/configs/polygon-archive-strategies.json';
import polygonExperimentalStrategies from '@/configs/polygon-experimental-strategies.json';
import optimismBareStrategies from '@/configs/optimism-bare-strategies.json';
import polygonzkevmBareStrategies from '@/configs/polygonzkevm-bare-strategies.json';
import baseBareStrategies from '@/configs/base-bare-strategies.json';
import baseArchiveStrategies from '@/configs/base-archive-strategies.json';

export const APP_DOMAIN = 'https://app.mellow.finance';
export const LANDING_DOMAIN = 'https://mellow.finance';
export const APPLY_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLScJXC08UK1A2b4-WydHpMsvdHn7fyW5mCdqlbZuiznCTN4D0A/viewform';
export const HOLESKY_RPC =
  'https://lb.drpc.org/ogrpc?network=holesky&dkey=As-DyyPNDUfnlRRhTL4jKLCWjjGHHRIR764CtigucSjy';

export const MAINNET_CHAIN_ID = 1;
export const POLYGON_CHAIN_ID = 137;

export const THRESHOLD_WSTETH = 0.001;
export const THRESHOLD_BASE_TOKEN = 1;

export const NETWORKS: { [key: number]: Network } = {
  1: 'ethereum',
  1101: 'polygonzkevm',
  137: 'polygon',
  1284: 'moonbeam',
  42161: 'arbitrum',
  10: 'optimism',
  8453: 'base',
  17000: 'holesky',
  9889: 'fuel',
  48900: 'zircuit',
};

export const NETWORK_CHAIN: { [key: string]: number } = Object.fromEntries(
  Object.entries(NETWORKS).map(([key, value]) => [value, Number(key)]),
);

export const NETWORKS_LIST = Object.values(NETWORKS);

export const isProduction = process.env.MODE === 'prod';

export const isProductionApp = () =>
  typeof window !== 'undefined' && window.origin === 'https://app.mellow.finance';

export const INITIAL_STRATEGIES = [
  ...ethereumBareStrategies,
  ...ethereumArchiveStrategies,
  ...polygonBareStrategies,
  ...polygonArchiveStrategies,
  ...polygonExperimentalStrategies,
  ...arbitrumArchiveStrategies,
  ...moonbeamArchiveStrategies,
  ...optimismBareStrategies,
  ...polygonzkevmBareStrategies,
  ...baseBareStrategies,
  ...baseArchiveStrategies,
].filter(strategy => (isProduction ? !strategy.id.includes('stage') : true));

export const TOKENS = new Set(INITIAL_STRATEGIES.map(strategy => strategy.tokens).flat());

export const SUPPORTED_CHAIN_IDS = INITIAL_STRATEGIES.reduce((networks, curStrategy) => {
  networks.add(NETWORK_CHAIN[curStrategy.network]);
  return networks;
}, new Set<number>());

export const RESTAKE_SUPPORTED_CHAIN_IDS = new Set(isProduction ? [1] : [1, 17000]);

export const ETHERSCAN: Record<Network, string> = {
  ethereum: 'https://etherscan.io',
  polygon: 'https://polygonscan.com',
  optimism: 'https://optimistic.etherscan.io',
  moonbeam: 'https://moonscan.io',
  arbitrum: 'https://arbiscan.io',
  polygonzkevm: 'https://zkevm.polygonscan.com',
  base: 'https://basescan.org',
  holesky: 'https://holesky.etherscan.io/',
  unknown: 'https://etherscan.io',
  fuel: 'https://app.fuel.network',
  zircuit: 'https://explorer.zircuit.com',
};

export const BLOCKSCOUT: Partial<Record<Network, string>> = {
  ethereum: 'https://eth.blockscout.com',
  holesky: 'https://eth-holesky.blockscout.com/',
};

export const TOKEN_ADDRESS: Record<Network, Record<string, string>> = {
  ethereum: {
    usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    lido: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
  },
  optimism: {},
  unknown: {},
  polygon: {},
  moonbeam: {},
  arbitrum: {},
  polygonzkevm: {},
  holesky: {},
  base: {},
  fuel: {},
  zircuit: {},
};

export const PROTOCOLS = new Set(
  INITIAL_STRATEGIES.map(strategy => strategy.protocols)
    .flat()
    .filter((value): value is string => !!value),
);

export const rpc: Record<Network, { url: string; isAvailable: boolean }[]> = {
  ethereum: [
    { url: 'https://rpc.mevblocker.io', isAvailable: true },
    { url: 'https://mainnet.infura.io/v3/1e0764ca8992477d92a04fbdce548c97', isAvailable: true },
    {
      url: 'https://lb.drpc.org/ogrpc?network=ethereum&dkey=As-DyyPNDUfnlRRhTL4jKLBLpHdJJ1ER76DqhkHL9tz4',
      isAvailable: true,
    },
  ],
  polygon: [
    { url: 'https://1rpc.io/matic', isAvailable: true },
    { url: 'https://polygon-bor.publicnode.com', isAvailable: true },
    { url: 'https://endpoints.omniatech.io/v1/matic/mainnet/public', isAvailable: true },
    { url: 'https://polygon-bor.publicnode.com', isAvailable: true },
    { url: 'https://matic-mainnet-full-rpc.bwarelabs.com', isAvailable: true },
  ],
  moonbeam: [
    { url: 'https://rpc.ankr.com/moonbeam', isAvailable: true },
    { url: 'https://moonbeam.api.onfinality.io/public', isAvailable: true },
    { url: 'https://moonbeam.public.blastapi.io', isAvailable: true },
    { url: 'https://rpc.api.moonbeam.network', isAvailable: true },
    { url: 'https://1rpc.io/glmr', isAvailable: true },
  ],
  arbitrum: [
    { url: 'https://1rpc.io/arb', isAvailable: true },
    { url: 'https://arbitrum-one.public.blastapi.io', isAvailable: true },
    { url: 'https://arb-mainnet-public.unifra.io', isAvailable: true },
  ],
  optimism: [
    { url: 'https://endpoints.omniatech.io/v1/op/mainnet/public', isAvailable: true },
    { url: 'https://optimism.publicnode.com', isAvailable: true },
    { url: 'https://1rpc.io/op', isAvailable: true },
    { url: 'https://rpc.ankr.com/optimism', isAvailable: true },
    { url: 'https://optimism-mainnet.public.blastapi.io', isAvailable: true },
    { url: 'https://optimism-mainnet.infura.io', isAvailable: true },
  ],
  polygonzkevm: [
    { url: 'https://zkevm-rpc.com', isAvailable: true },
    { url: 'https://1rpc.io/zkevm', isAvailable: true },
  ],
  base: [
    { url: 'https://1rpc.io/base', isAvailable: true },
    { url: 'https://rpc.notadegen.com/base', isAvailable: true },
    { url: 'https://base.blockpi.network/v1/rpc/public', isAvailable: true },
    { url: 'https://base-mainnet.public.blastapi.io', isAvailable: true },
  ],
  holesky: [
    { url: HOLESKY_RPC, isAvailable: true },
    { url: 'https://ethereum-holesky.blockpi.network/v1/rpc/public', isAvailable: true },
    { url: 'https://ethereum-holesky-rpc.publicnode.com', isAvailable: true },
    { url: 'https://rpc-holesky.rockx.com', isAvailable: true },
    { url: 'https://endpoints.omniatech.io/v1/eth/holesky/public', isAvailable: true },
  ],
  unknown: [],
  fuel: [],
  zircuit: [],
};

export const avgBlockTimeByNetwork: Record<Network, number> = {
  ethereum: 12.3,
  polygon: 2.3,
  arbitrum: 15,
  moonbeam: 6,
  optimism: 2,
  polygonzkevm: 1,
  base: 6,
  holesky: 1,
  unknown: 0,
  fuel: 0,
  zircuit: 0,
};

export const networkSymbol: Record<Network, string> = {
  ethereum: 'ETH',
  polygon: 'MATIC',
  arbitrum: 'ETH',
  moonbeam: 'GLMR',
  optimism: 'ETH',
  polygonzkevm: 'ETH',
  base: 'ETH',
  holesky: 'ETH',
  unknown: 'ETH',
  fuel: 'ETH',
  zircuit: 'ETH',
};

export const networkRpcToSwitch: Record<Network, string> = {
  ethereum: 'https://mainnet.infura.io/v3/',
  polygon: 'https://polygon-rpc.com',
  arbitrum: 'https://arbitrum-mainnet.infura.io',
  moonbeam: 'https://rpc.api.moonbeam.network',
  optimism: 'https://optimism-mainnet.infura.io',
  polygonzkevm: 'https://zkevm-rpc.com',
  base: 'https://mainnet.base.org',
  holesky: 'https://endpoints.omniatech.io/v1/eth/holesky/public',
  unknown: '',
  fuel: '',
  zircuit: 'https://zircuit-mainnet.drpc.org',
};

export const defillamaNetworkMap: Record<Network, string> = {
  ethereum: 'ethereum',
  polygon: 'polygon',
  arbitrum: 'arbitrum',
  moonbeam: 'moonbeam',
  optimism: 'optimism',
  polygonzkevm: 'polygon_zkevm',
  base: 'base',
  holesky: 'holesky',
  unknown: '',
  fuel: '',
  zircuit: 'zircuit',
};

// const dataCollectorAddresses = {
//   ethereum: ['0xA66263C61D7CF787514f6440E524e55A63Eeb396'],
//   polygon: ['0xBf80F944E3B9F502Ad059341e97a3C10d48326Ba'],
// };

export const ZERO_BYTES =
  '0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000';

export const VOID_USER: Record<Network, string> = {
  ethereum: '0x929C3dAF2E2Be4C74A56EC01dF374bc46A34C6A1',
  unknown: '0x929C3dAF2E2Be4C74A56EC01dF374bc46A34C6A1',
  polygon: '0x929C3dAF2E2Be4C74A56EC01dF374bc46A34C6A1',
  moonbeam: '0xFEED781Dff47cb04E33AFE475DF1d48092819684',
  arbitrum: '0x0000000000000000000000000000000000000000',
  optimism: '0x0000000000000000000000000000000000000000',
  base: '0x929C3dAF2E2Be4C74A56EC01dF374bc46A34C6A1',
  polygonzkevm: '0x929C3dAF2E2Be4C74A56EC01dF374bc46A34C6A1',
  holesky: '0x0000000000000000000000000000000000000000',
  fuel: '0x0000000000000000000000000000000000000000',
  zircuit: '0x0000000000000000000000000000000000000000',
};

export const MOONBEAM_TOKEN_ORACLE = {
  USDC: '0xA122591F60115D63421f66F752EF9f6e0bc73abC',
  WGLMR: '0x4497B606be93e773bbA5eaCFCb2ac5E2214220Eb',
};
